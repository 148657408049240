@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import './custom.scss';

@include media-breakpoint-down(sm) {
    .container404 {
        background-position: 70% center;
        padding: 10% 20%;
        margin-bottom: 20px;

        .h1Selector {
            font-size: 58px;
            padding-top: 20px;
        }
    }
}

.container404 {
    background: url('/error.png') 70% center / 32% no-repeat rgba(255, 255, 255);
    padding: 10% 25%;
    margin-bottom: 20px;
    width: 100%;
    color: $color-dark;
    border: 1px solid $color-shade-gray;
}

@include media-breakpoint-up(md) {
    .h1Selector {
        font-size: 150px;
        line-height: 1.2;
    }
}

.h1Selector {
    color: $color-dark;
    font-size: 200px;
    font-family: Axiforma, sans-serif;
}

.spanSelector {
    height: 23px;
    width: 81px;
    color: $color-dark;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.2;
}

@include media-breakpoint-down(md) {
    .pSelector {
        max-width: 230px;
    }
}

.pSelector {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.2;
    margin-bottom: 20px;
    max-width: 350px;
}
